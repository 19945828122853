<template>
  <div class="container">
    <div class="title"><span
        style="color:#409EFF;margin-right:10px;cursor: pointer;"
        @click="$router.back()"
      > <i class="el-icon-arrow-left"></i>返回
      </span>
      {{title}}
    </div>
    <div
      class="content"
      v-loading="loading"
    >
      <div class="good_group">
        <div
          :class="['goods_item',selectedGoods.includes(item.goods_id)?'selected_item':'',item.status?'':'off_item']"
          v-for="item in goodsList"
          :key="item.goods_id"
          @click="clickGoods(item)"
        >
          {{item.name}}
          <div
            v-if="selectedGoods.includes(item.goods_id)"
            class="select_box"
          ></div>
        </div>
      </div>
      <el-button
        type="primary"
        style="margin-top:30px"
        @click="clickConfom"
      >立即申请</el-button>
    </div>
  </div>
</template>

<script>
import { getGoodsForTypeApi, getMyGoodsListApi, openGoodsApi } from '../../../api/goods'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      loading: false,
      goodsList: [],
      title: '',
      selectedGoods: [],
      myGoodsList: []
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    // console.log(this.$route)
    this.title = this.$route.query.name
    this.getGoodsList()
    this.getMyGoodsList()
  },
  mounted () { },
  // 组件方法
  methods: {
    clickGoods (val) {
      console.log(val, this.selectedGoods)
      const isHave = this.myGoodsList.find(item => item.goods_id === val.goods_id)
      if (isHave) return this.$message.error('已申请商品不可取消')
      console.log(isHave)

      if (!val.status) return this.$message.error('该商品已下架')

      const index = this.selectedGoods.findIndex(item => item === val.goods_id)
      if (index >= 0) {
        this.selectedGoods.splice(index, 1)
      } else {
        this.selectedGoods.push(val.goods_id)
      }
    },
    async clickConfom () {
      if (this.selectedGoods.length === 0) return this.$message.error('至少需要选择一个商品')
      const arr = []
      this.selectedGoods.forEach(item => {
        const isHave = this.myGoodsList.find(item1 => item1.goods_id === item)
        if (!isHave) {
          arr.push(item)
        }
      })
      console.log(arr)

      if (arr.length > 0) {
        const res = await openGoodsApi({
          type_id: this.$route.params.id,
          goods_ids: this.selectedGoods.join(',')
        })
        console.log(res)
        if (res && res.code === 0) {
          this.$message.success('申请成功')
          this.$router.replace('/goods/myApi')
        }
      } else {
        this.$message.success('申请成功')
        this.$router.replace('/goods/myApi')
      }
    },
    async getGoodsList () {
      this.loading = true
      const res = await getGoodsForTypeApi({
        type_id: this.$route.params.id,
        page: 1,
        limit: 100
      })
      this.loading = false
      console.log(res)
      if (res && res.code === 0) {
        this.goodsList = res.data.data
      }
    },
    async getMyGoodsList () {
      const res = await getMyGoodsListApi({
        page: 1,
        limit: 100,
        type_id: this.$route.params.id
      })
      // console.log(res)
      if (res && res.code === 0) {
        this.myGoodsList = res.data.data
        this.selectedGoods = res.data.data.map(item => item.goods_id)
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    width: 100%;
    padding: 20px 0 0 70px;
    box-sizing: border-box;
    overflow-y: auto;
    // justify-content: space-around;
    .good_group {
      // height: auto;
      display: flex;
      flex-wrap: wrap;
      // padding-left: 50px;
      .goods_item {
        position: relative;
        height: 30px;
        line-height: 30px;
        padding: 5px 30px;
        border-radius: 5px;
        border: 1px solid #9f9f9f;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        overflow: hidden;
        .select_box {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: -5px;
          right: -17px;
          height: 20px;
          width: 50px;
          background-color: #4977e9;
          transform: rotate(-45deg);
          &::after {
            content: "√";
            color: #fff;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            transform: rotate(45deg);
          }
        }
      }
      .selected_item {
        border-color: #4977e9;
      }
      .off_item {
        background-color: #eaeaea;
        cursor: no-drop;
      }
    }
  }
}
</style>
